import React from 'react';
import { Typography } from 'antd';
import './PrivacyPage.css'


const PrivacyPage = () => {
  const { Title, Paragraph, Link } = Typography;
  const link_click = {
    API_KEYS: "1",
    MODELS: "2",
    POLICY: "3",
    LICENSE: "4",
  }

  const handleLinkClick = (event) => {
    const linkNumber = event.target.getAttribute('data-link-number');
    switch (linkNumber) {
      case link_click.POLICY:
        let url3 = 'https://openai.com/policies/privacy-policy';
        window.open(url3);
        // window.open('/privacy', '_blank');
        break;
    }
  }


  return (
    <div className='body'>
      <div className='content'>
        <Title level={2}>Privacy Policy</Title>
        {/* <Paragraph className='Text'>
          Welcome to our Privacy Policy page. This page explains how we collect, use, and protect your personal information. Please read the following carefully:
        </Paragraph> */}
        <Paragraph className='Text' >
          This plugin solely utilizes OpenAI's API for data processing and strictly adheres to privacy protection principles. We do not collect any personal user data. For more information about OpenAI's privacy policy and data handling practices, please refer to:
          {/* <Link onClick={handleLinkClick} data-link-number={link_click.POLICY} className="pg-text-policy">
                  🔒Openai-privacy-policy
                </Link > */}
          <Link onClick={handleLinkClick} data-link-number={link_click.POLICY} className="pg-text-policy" target="_blank">
            🔒Openai-privacy-policy
          </Link>
        </Paragraph>


        <Title level={3}>Pro Version License Key Verification</Title>
        <Paragraph className='Text'>
          The Pro version requires the use of a license key for verification and activation.
          The license key is only used to verify the legitimacy of the Pro version, ensuring that users enjoy additional features and services.
          The user's license key and related data are stored only on the user's local device and are not transmitted to our servers or any third parties.
        </Paragraph>

        <Title level={3}>Protection of Personal Information</Title>
        <Paragraph className='Text'>
          The license key in the Pro version may contain some personal information such as the customer's name and email address.
          This personal information is solely used for license verification and activation and will not be used for any other purposes.
          We take appropriate technical and organizational measures to protect the security of the license key and the personal information it contains.
        </Paragraph>

        <Title level={3}>Information Security Measures</Title>
        <Paragraph className='Text'>
          We will take reasonable technical and organizational measures to protect the personal information of users, including the license key and its related data.
          While we have implemented security measures, we cannot guarantee the absolute security of information transmitted through the plugin due to the inherent risks of internet transmission.
        </Paragraph>

        <Title level={3}>User Rights and Choices</Title>
        <Paragraph className='Text'>
          Under applicable laws, users may have the right to access, correct, or delete the personal information we hold related to their license key.
          If users wish to exercise these rights or have any questions regarding their personal information, they can contact us using the provided contact details.
        </Paragraph>

        <Title level={3}>Notification of Privacy Policy Updates</Title>
        <Paragraph className='Text'>
          We may periodically update our privacy policy to reflect our practices and changes in applicable laws.
          Users will be notified of these changes by posting the updated privacy policy on our website.
          We encourage users to regularly review the privacy policy for the latest information.
        </Paragraph>

        <Title level={3}>Contact Us</Title>
        <Paragraph className='Text'>
          If you have any questions or concerns about our privacy policy or the handling of your personal information, please contact us using the following contact details:
          📧 Email: feedback@ai-tab.com
        </Paragraph>
      </div>
    </div>
  );
};

export default PrivacyPage;