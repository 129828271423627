import { React, useEffect } from 'react';
import PrivacyPage from './components/PrivacyPage';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Homepage from './components/Homepage';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
    </Routes>
  );
};

export default App;