import { React, useEffect } from 'react';
import { Typography, Button, Card, Col, Row, Statistic, Divider, Layout, Flex, Space, Image } from 'antd';
import Video from './Video';
import logo from "./../assets/icon.png";
import { Link } from 'react-router-dom';
import Paypage from './paypage'
// import { ThemeProvider } from "~theme"
import { Content, Header } from 'antd/es/layout/layout';
import Title from 'antd/es/typography/Title';
import './Homepage.css'
import { useNavigate, useLocation } from 'react-router-dom';
import edgeLogo from './../assets/edge.svg';
import chromeLogo from './../assets/chrome.svg';
import howToStartPng from './../assets/howtostart.png';

const Homepage = () => {
    const { Text, Link, Paragraph } = Typography;

    const navigate = useNavigate();
    const location = useLocation();
    // const { id, title, content } = location.state

    useEffect(() => {
        // console.log('---------------' + location.hash);
        if (location.hash === '#paypage') {
            // navigate.pushState(null, null, location.hash);
            const paypageElement = document.getElementById('paypage');
            if (paypageElement) {
                paypageElement.scrollIntoView({ behavior: 'smooth' });
            }
            // navigate('/paypage');
        }
        //  else if (location.hash === '#privacy') {
        //     // navigate.pushState(null, null, location.hash);
        //     const paypageElement = document.getElementById('privacy');
        //     if (paypageElement) {
        //         paypageElement.scrollIntoView({ behavior: 'smooth' });
        //     }
        //     // navigate('/paypage');
        // }

    }, [])


    const onClickChrome = async (event) => {
        // navigate('/paypage');
        // alert('Coming soon...');
        const url = 'https://chromewebstore.google.com/detail/aitabgroup/andiflimekpdaccmpcmjabebkmfekmjc'
        window.open(url);
    }
    const onClickEdge = async (event) => {
        // navigate('/paypage');
        // alert('Coming soon...');
        const url = 'https://microsoftedge.microsoft.com/addons/detail/aitabgroup/iibgmpbaekofjjdokalaoicpcdlalika'
        window.open(url);
    }

    const link_click = {
        API_KEYS: "1",
        MODELS: "2",
        POLICY: "3",
        LICENSE: "4",
    }
    const handleLinkClick = (event) => {
        const linkNumber = event.target.getAttribute('data-link-number');
        switch (linkNumber) {
            case link_click.POLICY:
                //   let url3 = 'https://openai.com/policies/privacy-policy';
                //   window.open(url3);
                window.open('/privacy', '_blank');
                break;
        }
    }

    return (
        <div className="app">
            {/* <Header className='header' > */}
            {/* </Header> */}
            <Content className='content'>
                <Space direction='vertical' className='space'>
                    <p>
                        <Flex gap={'middle'} align="center" justifyContent="center">
                            <img src={logo} width={100} height={100} />
                            <Text className="title" >AITabGroup</Text>
                        </Flex>
                    </p>
                    <p>
                        <Text className='title-text'>
                            Welcome to our browser extension! Our extension utilizes AI technology to automatically categorize your browser tabs.
                            <br />
                            One-click automated creation of categorized tab groups.
                        </Text>
                    </p>
                    <p>
                        <Text className='content-title-text'>
                            Start Free Trail 👇 </Text>
                    </p>
                    <p>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button onClick={onClickChrome} className="buttonShop margin-right" >
                                <img src={chromeLogo} alt="Logo" className="button-icon" />
                                Chrome
                            </Button>
                            <Button onClick={onClickEdge} className="buttonShop"  >
                                <img src={edgeLogo} alt="Logo" className="button-icon" />
                                Edge
                            </Button>
                        </div>
                    </p>
                    <p>
                        <Video />
                    </p>
                    <p>
                        <Text className='content-title-text'>
                            How To Start </Text>
                    </p>
                    <p>
                        <Image
                            width={560} height={664}
                            src={howToStartPng}
                        />
                    </p>
                    <p>
                        <Text className='content-text-1'>
                            1. Click the extension icon.<br />
                            2. Locate AITabGroup and click on "Pin".<br />
                            3. Right-click the extension icon and select "Options".<br />
                            4. In the options interface, enter the OpenAI key.<br />
                        </Text>
                    </p>
                    <p>
                        <Text className='content-title-text'>
                            How AITabGroup works </Text>
                    </p>
                    <p>
                        <Text className='content-text'>
                            This Chrome extension is used as a tool to automatically categorize and organize browser tabs. It utilizes OpenAI's API to assist you in automatically categorizing your tabs.
                        </Text>
                    </p>
                    <p>
                        <Text className='content-title-text'>
                            Privacy </Text>
                    </p>
                    <p >
                        <Text className='content-text' >
                            Please check :
                            <Link onClick={handleLinkClick} data-link-number={link_click.POLICY} className="pg-text-policy" target="_blank">
                                🔗 Privacy-Policy
                            </Link>
                        </Text>
                    </p>
                    <p>
                        <Text className='content-title-text'>
                            Upgrade to Pro now</Text>
                    </p>
                    {/* http://example.com/#paypage */}
                    <p >
                        <div id="paypage">
                            <Paypage />
                        </div>
                    </p>


                    <p >
                        <Text className='content-text'>
                            Feedback: 📧
                            <a href="https://forms.gle/xr15u6y9Mo9dTt8k6" target="_blank" rel="noopener noreferrer">
                                contact us
                            </a>
                            <span className="space"></span>
                            Twitter: 🐦
                            <a href="https://twitter.com/E_Polyphony" target="_blank" rel="noopener noreferrer">
                                @epolyphony
                            </a>
                            <span className="space"></span>
                            © 2024 AITabGroup. All rights reserved.
                        </Text>
                    </p>
                </Space>
                {/* <Routes>
          <Route path="/paypage" element={<Paypage />} />
        </Routes> */}
            </Content>
        </div>
    );
};

export default Homepage;