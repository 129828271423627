// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// 导入翻译文件等资源
import translationEN from './assets/locales/en.json';
// import translationZH from './assets/locales/zh.json';

// 初始化i18n实例
i18n
  .use(initReactI18next)
  .init({
    // 设置支持的语言
    fallbackLng: 'en',
    supportedLngs: ['en', 'zh'],

    // 导入翻译资源
    resources: {
      en: {
        translation: translationEN,
      },
    //   zh: {
    //     translation: translationZH,
    //   },
    },

    // 其他配置选项
  });

export default i18n;