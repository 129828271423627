import React from 'react';

const Video = () => {
  return (
    <div style={{
      display: 'flex', justifyContent: 'center',
      alignItems: 'center',
    }}>
      <iframe width="616" height="347"
        src="https://www.youtube.com/embed/eXxzIKB3XTA?si=UV8EIRQWlqRTpdak" title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
    </div>
  );
};

export default Video;