import React from 'react';
import { Button, Card, Col, Row, Statistic, Divider, Badge, Flex } from 'antd';
// import { ThemeProvider } from "~theme"
// import { getMessage } from '~textMsg';
import './paypage.css'
// import { toBuyPage } from '~pro/license/lemon';
import { Content, Header } from 'antd/es/layout/layout';
import Title from 'antd/es/typography/Title';
import { useTranslation } from 'react-i18next';
import i18n from './../i18n'; // 导入i18n配置
import { I18nextProvider } from 'react-i18next';

const Paypage = () => {
  const { t } = useTranslation();

  function handleClick(subscriptionType) {
    if (subscriptionType === 'monthly') {
      const checkoutUrl = 'https://epolyphony.lemonsqueezy.com/checkout/buy/11bc1b45-caa1-4295-a38c-3b459b2a69a3'
      // const url = checkoutUrl + add + discount;
      window.open(checkoutUrl, '_blank');

    } else if (subscriptionType === 'yearly') {
      // const checkoutUrl = 'https://epolyphony.lemonsqueezy.com/checkout/buy/7c0ea281-03ee-4a2a-81d1-eb894f87606b'
      // const checkoutUrl = 'https://epolyphony.lemonsqueezy.com/checkout/buy/10bdfdcf-578d-496b-92e9-e5f55bd46b4b'
      const checkoutUrl = 'https://epolyphony.lemonsqueezy.com/checkout/buy/abdf68fa-af2f-4437-a2d2-8459a3433eb4'
      const add = '?checkout%5Bdiscount_code%5D='
      // const discount = 'I4NDE1MQ'
      const discount = 'I5NJCYMQ'
      const url = checkoutUrl + add + discount;
      window.open(url, '_blank');
    }

  }

  // const handleClick = () => {


  // };

  function getMessage(messageName) {
    return t(messageName);
  }
  const price_month = 1.99
  const price_year = price_month * 12
  const price_discount = price_year * 0.75



  return (
    <I18nextProvider i18n={i18n}>
      <Row gutter={16} className="row-content"  >
        <Col span={8} className="col-content">
          <Card className='card' title={getMessage('pay_basic')} bordered={true}>
            <p>
              <Statistic className='card_price' valueStyle={{ color: '#0c87fa', fontSize: 26 }}
                title="PRICE" value={0} prefix='$' precision={2} />
            </p>
            <p >{getMessage('pay_auto_categorization')}</p>
            <p >{getMessage('pay_free_trail')}</p>
            {/* <p style={{ textDecoration: 'line-through' }}>{getMessage('pay_save_markdown_no')}</p>
              <p style={{ textDecoration: 'line-through' }}>{getMessage('pay_save_bookmarks_no')}</p>
              <p style={{ textDecoration: 'line-through' }}>{getMessage('pay_custom_categorization_no')}</p>
              <p style={{ textDecoration: 'line-through' }}>{getMessage('pay_manage_subscription_no')}</p> */}
          </Card>
        </Col>
        <Col span={8} className="col-content">
          <Card className='card' title={getMessage('pay_month')} bordered={true}>
            <p>
              <Statistic className='card_price' valueStyle={{ color: '#0c87fa', fontSize: 26 }}
                title="PRICE" value={price_month} prefix='$' suffix='/month' precision={2} />

            </p>
            <p>{getMessage('pay_auto_categorization')}</p>
            <p>{getMessage('pay_shown_tree')}</p>
            <p>{getMessage('pay_save_markdown')}</p>
            <p>{getMessage('pay_save_bookmarks')}</p>
            <p>{getMessage('pay_manage_subscription')}</p>
            <p> <Divider /></p>
            <p>
              <Button type="primary" className="button" onClick={() => handleClick('monthly')}>
                {getMessage('pay_subscribe')}
              </Button>
            </p>
          </Card>
        </Col>
        <Col span={8} className="col-content">
          <Badge.Ribbon text="Limited-Time Promotion" color="blue">
            <Card className='card' title={getMessage('pay_Annual')} bordered={true}>
              <p style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                <Statistic className='card_price' valueStyle={{ color: '#0c87fa', fontSize: 26 }}
                  title="PRICE" value={price_discount} prefix='$'
                  suffix={
                    '/year'
                  } precision={2} />
                <span
                  style={{
                    fontSize: '22px',
                    marginLeft: '8px', marginBottom: '3px',
                    textDecoration: 'line-through',
                  }}
                >
                  ${price_year}
                </span>
              </p>
              <p>{getMessage('pay_auto_categorization')}</p>
              <p>{getMessage('pay_shown_tree')}</p>
              <p>{getMessage('pay_save_markdown')}</p>
              <p>{getMessage('pay_save_bookmarks')}</p>
              <p>{getMessage('pay_manage_subscription')}</p>
              <p> <Divider /></p>
              <p>
                <Button type="primary" className="button" onClick={() => handleClick('yearly')}>
                  {getMessage('pay_subscribe')}
                </Button>
              </p>
            </Card>
          </Badge.Ribbon>
        </Col>
      </Row>
    </I18nextProvider>
  )
}

export default Paypage;